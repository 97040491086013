import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import SkyGrid from "../../base/SkyGrid/skyGrid";
import styles from "./VirtualNumberExtension.module.css";
import { fetchExtensions } from "../../../api/endpoint";
import { notyf } from "../../../notyf";
import { ExtensionStatus, GetExtensionResponse } from "../../../models/response/GetExtensionResponse";
import { AuthContext } from "../../../contextApi/AuthContext/authContext";
import SkyButton, { ButtonSize } from "../../base/SkyButton/SkyButton";
import AddExtension from "./AddExtension/AddExtension";
import SkyTooltip from "../../base/SkyTooltip/SkyTooltip";
import {
  Add as AddIcon,
  DeleteOutline,
  EditOutlined,
  FastForwardOutlined,
  ManageAccountsOutlined,
  MoreVert as MoreVertIcon,
  Key as KeyIcon
} from "@mui/icons-material";
import VirtualNumberRedirectionDetails from "./VirtualNumberRedirectionDetails/VirtualNumberRedirectionDetails";
import VirtualNumberRegistrationDetails from "./VirtualNumberRegistrationDetails/VirtualNumberRegistrationDetails";
import DeleteExtension from "./DeleteExtension/DeleteExtension";
import ManageExtensionPassword from "./ManageExtensionPassword/ManageExtensionPassword";

const VirtualNumberExtension: React.FC = () => {
  const { accessToken } = useContext(AuthContext);
  const [isAddExtensionDialogOpen, setIsAddExtensionDialogOpen] = useState(false);
  const [selectedExtensionRedirections, setSelectedExtensionRedirections] = useState<GetExtensionResponse | null>(null);
  const [selectedExtensionRegistrations, setSelectedExtensionRegistrations] = useState<GetExtensionResponse | null>(null);
  const [selectedExtensionToDelete, setSelectedExtensionToDelete] = useState<GetExtensionResponse | null>(null);
  const [selectedExtensionForPassword, setSelectedExtensionForPassword] = useState<GetExtensionResponse | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedExtension, setSelectedExtension] = useState<GetExtensionResponse | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMoreClick = (event: React.MouseEvent<HTMLElement>, extension: GetExtensionResponse) => {
    if (selectedExtension?.id === extension.id && anchorEl) {
      handleClose();
      return;
    }
    setAnchorEl(event.currentTarget);
    setSelectedExtension(extension);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedExtension(null);
  };

  const { data: extensions = [], isLoading, refetch: reFetchExtensions } = useQuery<GetExtensionResponse[]>(
    ["extensions", accessToken],
    () => fetchExtensions(accessToken || ""),
    {
      refetchInterval: (data) => {
        if (!data) return false;
        return data.some(extension => extension.status !== ExtensionStatus.AGENT_REGISTERED) ? 30000 : false;
      },
      enabled: !!accessToken,
      onError: () => {
        notyf.error("Failed to fetch extensions");
      }
    }
  );

  const getStatusClassAndTooltip = (status: ExtensionStatus) => {
    switch (status) {
      case ExtensionStatus.UNPROVISIONED:
        return {
          className: styles.inactiveStatus,
          tooltipText: "Extension is Inactive",
          testId: "status-unprovisioned"
        };
      case ExtensionStatus.CONFIGURED:
        return {
          className: styles.pendingStatus,
          tooltipText: "Extension is Configured",
          testId: "status-configured"
        };
      case ExtensionStatus.AGENT_REGISTERED:
        return {
          className: styles.activeStatus,
          tooltipText: "Extension is Registered on Softphone",
          testId: "status-agent-registered"
        };
      default:
        return {
          className: styles.pendingStatus,
          tooltipText: "Unknown Extension Status",
          testId: "status-unknown"
        };
    }
  };


  const headers = [
    () => <span>Extensions</span>,
    () => <span>SIP Host</span>,
    () => <span>Status</span>,
    () => (
      <SkyButton
        size={ButtonSize.FIT_CONTENT}
        onClick={() => setIsAddExtensionDialogOpen(true)}
        testId="create-extension-button"
        renderer={() => (
          <div className={styles.createExtensionBtn}>
            <AddIcon />
            <span> Create Extension </span>
          </div>
        )}
      />
    )
  ];

  const data = extensions.map((extension) => [
    () => <span>{extension.username}</span>,
    () => <span>{extension.sipHost}</span>,
    () => {
      const { className, tooltipText, testId } = getStatusClassAndTooltip(extension.status);
      return (
        <SkyTooltip tooltipText={tooltipText}>
          <div className={`${styles.dot} ${className}`} data-testid={testId} />
        </SkyTooltip>
      );
    },
    () => (
      <div className={styles.actionIcons}>
        <SkyTooltip tooltipText="View Registration details">
          <span
            className={styles.icon}
            data-testid="manage-account-icon"
            onClick={() => setSelectedExtensionRegistrations(extension)}
          >
            <ManageAccountsOutlined />
          </span>
        </SkyTooltip>
        <SkyTooltip tooltipText="View Redirections">
          <span
            className={styles.icon}
            data-testid={"fast-forward-icon"}
            onClick={() => setSelectedExtensionRedirections(extension)}
          >
            <FastForwardOutlined />
          </span>
        </SkyTooltip>
        <div className={styles.moreOptionsWrapper}>
          <SkyTooltip tooltipText="More options">
            <span
              ref={buttonRef}
              className={styles.icon}
              onClick={(e) => handleMoreClick(e, extension)}
              data-testid="more-options-icon"
            >
              <MoreVertIcon />
            </span>
          </SkyTooltip>
          {Boolean(anchorEl) && selectedExtension?.id === extension.id && (
            <div ref={menuRef} className={styles.moreOptionsMenu}>
              <div
                className={`${styles.menuItem} ${extension.status === ExtensionStatus.UNPROVISIONED ? styles.disabled : ""}`}
                onClick={() => {
                  if (extension.status !== ExtensionStatus.UNPROVISIONED) {
                    setSelectedExtensionForPassword(extension);
                    handleClose();
                  }
                }}
              >
                <KeyIcon />
                <span data-testid="manage-password-icon">Manage Password</span>
              </div>
              <div className={styles.menuItem}>
                <EditOutlined />
                <span data-testid="edit-icon">Edit Extension</span>
              </div>
              <div
                className={`${styles.menuItem} ${styles.deleteItem}`}
                onClick={() => {
                  setSelectedExtensionToDelete(extension);
                  handleClose();
                }}
              >
                <DeleteOutline />
                <span data-testid="delete-icon">Delete </span>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  ]);

  return (
    <div className={styles.virtualNumberExtension}>
      <div className={styles.virtualNumberExtensionData}>
        <SkyGrid
          isLoading={isLoading}
          headerRenderer={headers}
          renderer={data}
          colorBarSpan={3}
        />
        {isAddExtensionDialogOpen && (
          <AddExtension
            isOpen={isAddExtensionDialogOpen}
            onClose={() => setIsAddExtensionDialogOpen(false)}
            onSuccess={reFetchExtensions}
          />
        )}
        {selectedExtensionRedirections && (
          <VirtualNumberRedirectionDetails
            isOpen={!!selectedExtensionRedirections}
            onClose={() => setSelectedExtensionRedirections(null)}
            redirections={selectedExtensionRedirections.numberRedirections}
          />
        )}
        {selectedExtensionRegistrations && (
          <VirtualNumberRegistrationDetails
            isOpen={!!selectedExtensionRegistrations}
            onClose={() => setSelectedExtensionRegistrations(null)}
            registrations={selectedExtensionRegistrations.registrations}
          />
        )}
        {selectedExtensionToDelete && (
          <DeleteExtension
            isOpen={!!selectedExtensionToDelete}
            onClose={() => setSelectedExtensionToDelete(null)}
            onSuccess={reFetchExtensions}
            extensionUsername={selectedExtensionToDelete.username}
            extensionId={selectedExtensionToDelete.id}
          />
        )}
        {selectedExtensionForPassword && (
          <ManageExtensionPassword
            isOpen={!!selectedExtensionForPassword}
            onClose={() => setSelectedExtensionForPassword(null)}
            extension ={selectedExtensionForPassword}
          />
        )}
      </div>
    </div>
  );
};

export default VirtualNumberExtension;